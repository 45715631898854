<template>
  <div v-if="userRole">

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">User Role: <small>{{ userRole.title }}</small></h4>
    </div>
    <div class="card">
      <div class="rainbow-background"></div>
      <table  class="card-table table">
        <tbody class="text-left">
        <tr>
          <th scope="col">ID</th>
          <td>{{ userRole.id }}</td>
        </tr>
        <tr>
          <th scope="col">Title</th>
          <td>{{ userRole.title }}</td>
        </tr>
        <tr>
          <th scope="col">Created At</th>
          <td>{{ new Date(userRole.created_at).toDateString() }}, {{ new Date(userRole.created_at).toLocaleTimeString() }}</td>
        </tr>
        <tr>
          <th scope="col">Updated At</th>
          <td>{{ new Date(userRole.updated_at).toDateString() }}, {{ new Date(userRole.updated_at).toLocaleTimeString() }}</td>
        </tr>
        </tbody>
      </table>
      <div class="card-footer">
        <div class="btn-group float-right">
          <router-link :to="{ name: 'UserRoleIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
          <router-link :to="{ name: 'UserRoleEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
          <button v-on:click="$refs.userRoleDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>

    <!-- Modal Component -->
    <b-modal ref="userRoleDeleteModal" header-bg-variant="danger" header-text-variant="light" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteUserRole" centered>
      <p>Are you sure you want to delete this User Role?</p>
    </b-modal>

  </div>
  <loading-overlay v-else></loading-overlay>
</template>

<script>
import { UserRoleService } from '@/common/services/user_role.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'UserRoleShow',
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      userRole: null,
    };
  },
  created() {
    this.getUserRole();
  },
  methods: {
    getUserRole() {
      UserRoleService.get(this.$route.params.id)
        .then((response) => {
          this.userRole = response.data;
        });
    },
    deleteUserRole() {
      UserRoleService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'UserRoleIndex' });
          this.$notify({
            title: `Deleted ${response.data.title}`,
            text: 'Successfully deleted User Role.',
            type: 'success',
            width: 350,
          });
        });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import "../../styles/views/user_roles";
</style>
